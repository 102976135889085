.otpBox {
    width: 48px;
    height: 48px;
    margin: 11px 10px 14px 0;
    border-radius: 4px;
    border: solid 1px #e6e6e6;
    background-color: #fff;
    text-align: center;
    font-size: 20px;
}
.otpError {
    border: solid 1px #ff3c2b;
}
.otpSelected {
    border: solid 1px #36c;
}
.otpDeselected {
    border: solid 1px #e6e6e6;
}
