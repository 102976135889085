.td-none {
    text-decoration: none;
}

.c-light-grey {
    color: #666;
}

.c-dark-grey {
    color: #4d4d4d;
}

.c-light-blue {
    color: #36c;
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tt-c {
    text-transform: uppercase;
}
.tt-lowercase {
    text-transform: lowercase;
}
