.breadcrumb-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    li {
        display: inline-flex;
        align-items: center;
    }
    li:not(:last-child)::after {
        content: '>';
        padding: 0 4px;
        color: #808080;
    }
}

.qnaContainer {
    grid-template-columns: 28px 1fr;
    grid-template-areas:
        'sno question'
        '. answer';
}

.qnaSno {
    grid-area: sno;
}

.qnaQuestion {
    grid-area: question;
}

.qnaAnswer {
    grid-area: answer;
}
