@font-face {
    font-family: 'cleartrip';
    src: url('../../assets/fonts/customIcon/cleartrip.woff?xjfsn2') format('woff') /* Safari, Android, iOS, Modern */,
        url('../../assets/fonts/customIcon/cleartrip.ttf?xjfsn2') format('truetype') /* Modern Browsers */,
        url('../../assets/fonts/customIcon/cleartrip.svg?xjfsn2#cleartrip') format('svg') /* Legacy iOS */,
        url('../../assets/fonts/customIcon/cleartrip.eot?xjfsn2#iefix') format('embedded-opentype');
    /* IE6-IE8 */
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cleartrip' !important;
    font-size: 24px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.icon-room-card--small {
        font-size: 22px;
    }
}

.CTICON_THUMBS_UP:before {
    content: '\e9b2';
}
.CTICON_NO_MEAL:before {
    content: '\e9c0';
}
.CTICON_THUMBS_DOWN_RED:before {
    content: '\e9ad';
    color: #c83232;
}
.CTICON_NEUTRAL_FACE_YELLOW:before {
    content: '\e9ae';
    color: #df9641;
}
.CTICON_THUMBS_UP_GREEN:before {
    content: '\e9af';
    color: #11a670;
}
.CTICON_CALENDAR_NEGATIVE:before {
    content: '\e9b0';
}
.CTICON_CALENDAR_STEADY:before {
    content: '\e9b1';
}
.CTICON_SAD_FACE:before {
    content: '\e9b3';
}
.CTICON_NEUTRAL_FACE:before {
    content: '\e9b4';
}
.CTICON_OVERALL_SENTIMENT:before {
    content: '\e9b5';
}
.CTICON_PERONA_BUSINESS:before {
    content: '\e9b6';
}
.CTICON_FAVORIATE:before {
    content: '\e9b7';
}
.CTICON_DIVERSITY_HEART:before {
    content: '\e9b8';
}
.CTICON_DIVERSITY_GROUP:before {
    content: '\e9b9';
}
.CTICON_FAVORIATE_HEART_FILLED:before {
    content: '\e9ba';
    color: #e13939;
}
.CTICON_MOUNTAIN_FLAG:before {
    content: '\e9bb';
}
.CTICON_RATINGS_STAR:before {
    content: '\e9bc';
}
.CTICON_RECENCY_NEGATIVE:before {
    content: '\e9bd';
}
.CTICON_RECENCY_NEUTRAL:before {
    content: '\e9be';
}
.CTICON_DIVERSITY_PEOPLE:before {
    content: '\e9bf';
}
.CTICON_HAMBURGER:before {
    content: '\e905';
}
.CTICON_SPARKLE:before {
    content: '\e900';
}
.CTICON_BUS:before {
    content: '\e901';
}
.CTICON_FLIGHT:before {
    content: '\e902';
}
.CTICON_flight:before {
    content: '\e903';
}
.CTICON_OFFER:before {
    content: '\e904';
}
.CTICON_ACCESSIBILITY:before {
    content: '\e906';
}
.CTICON_ADJOINING_ROOMS:before {
    content: '\e907';
}
.CTICON_AIR_CONDITIONER:before {
    content: '\e908';
}
.CTICON_AIR_PURIFIER:before {
    content: '\e909';
}
.CTICON_BATH_SOAP:before {
    content: '\e90a';
}
.CTICON_BATHROOM:before {
    content: '\e90b';
}
.CTICON_BED:before {
    content: '\e90c';
}
.CTICON_BLANKET:before {
    content: '\e90d';
}
.CTICON_BLU-RAY_PLAYER:before {
    content: '\e90e';
}
.CTICON_CARPET:before {
    content: '\e90f';
}
.CTICON_CEILING_FAN-1:before {
    content: '\e910';
}
.CTICON_CEILING_FAN:before {
    content: '\e911';
}
.CTICON_CHIDLREN_SERVICES:before {
    content: '\e912';
}
.CTICON_CHILDREN_ENTERTAINMENT:before {
    content: '\e913';
}
.CTICON_COMMON_SEATING:before {
    content: '\e914';
}
.CTICON_COMPUTER:before {
    content: '\e915';
}
.CTICON_CURTAINS:before {
    content: '\e916';
}
.CTICON_DINING:before {
    content: '\e917';
}
.CTICON_DISHWASHER:before {
    content: '\e918';
}
.CTICON_DUSTBIN:before {
    content: '\e919';
}
.CTICON_ELECTRIC_BLANKET:before {
    content: '\e91a';
}
.CTICON_ELECTRIC_KETTLE:before {
    content: '\e91b';
}
.CTICON_FALLBACK:before {
    content: '\e91c';
}
.CTICON_FAX_MACHINE:before {
    content: '\e91d';
}
.CTICON_FIRE_SAFETY:before {
    content: '\e91e';
}
.CTICON_FITNESS:before {
    content: '\e91f';
}
.CTICON_FRUIT:before {
    content: '\e920';
}
.CTICON_GAME_CONSOLE:before {
    content: '\e921';
}
.CTICON_GEYSER:before {
    content: '\e922';
}
.CTICON_HAIR_DRYER:before {
    content: '\e923';
}
.CTICON_HANGERS:before {
    content: '\e924';
}
.CTICON_HOUSEKEEPING:before {
    content: '\e925';
}
.CTICON_HUMIDIFIER:before {
    content: '\e926';
}
.CTICON_INDOOR_PLAY:before {
    content: '\e927';
}
.CTICON_INTERCOM:before {
    content: '\e928';
}
.CTICON_IRON:before {
    content: '\e929';
}
.CTICON_KITCHEN:before {
    content: '\e92a';
}
.CTICON_KITCHENWARE:before {
    content: '\e92b';
}
.CTICON_LAPTOP:before {
    content: '\e92c';
}
.CTICON_LAUNDRY:before {
    content: '\e92d';
}
.CTICON_LIFT:before {
    content: '\e92e';
}
.CTICON_LIVING_ROOM:before {
    content: '\e92f';
}
.CTICON_LOUNGE:before {
    content: '\e930';
}
.CTICON_MEDICAL_FACILITIES:before {
    content: '\e931';
}
.CTICON_MICROWAVE:before {
    content: '\e932';
}
.CTICON_MINIBAR:before {
    content: '\e933';
}
.CTICON_MIRROR:before {
    content: '\e934';
}
.CTICON_NOT_ALLOWED_SMOKING:before {
    content: '\e935';
}
.CTICON_OUTDOOR_DINING:before {
    content: '\e936';
}
.CTICON_PILLOW:before {
    content: '\e937';
}
.CTICON_POWER_BACKUP:before {
    content: '\e938';
}
.CTICON_POWER_POINT:before {
    content: '\e939';
}
.CTICON_REFRIGERATOR:before {
    content: '\e93a';
}
.CTICON_ROOM_SERVICE:before {
    content: '\e93b';
}
.CTICON_SAFE_DEPOSIT:before {
    content: '\e93c';
}
.CTICON_SANITIZER:before {
    content: '\e93d';
}
.CTICON_SATELLITE:before {
    content: '\e93e';
}
.CTICON_SEWING_KIT:before {
    content: '\e93f';
}
.CTICON_SHAMPOO:before {
    content: '\e940';
}
.CTICON_SHOWER_CAP:before {
    content: '\e941';
}
.CTICON_SHOWER:before {
    content: '\e942';
}
.CTICON_SLIPPERS:before {
    content: '\e943';
}
.CTICON_SMOKE_DETECTOR:before {
    content: '\e944';
}
.CTICON_SMOKING:before {
    content: '\e945';
}
.CTICON_SNACKS:before {
    content: '\e946';
}
.CTICON_SOUNDPROOF:before {
    content: '\e947';
}
.CTICON_STAIRS:before {
    content: '\e948';
}
.CTICON_SWIMMING_POOL:before {
    content: '\e949';
}
.CTICON_TABLET:before {
    content: '\e94a';
}
.CTICON_TEA_MACHINE:before {
    content: '\e94b';
}
.CTICON_TELEVISION:before {
    content: '\e94c';
}
.CTICON_TOASTER:before {
    content: '\e94d';
}
.CTICON_TOILET_PAPER:before {
    content: '\e94e';
}
.CTICON_TOILET:before {
    content: '\e94f';
}
.CTICON_TOILETRIES:before {
    content: '\e950';
}
.CTICON_TOWELS:before {
    content: '\e951';
}
.CTICON_UMBRELLA:before {
    content: '\e952';
}
.CTICON_WAKEUP_SERVICE:before {
    content: '\e953';
}
.CTICON_WARDROBE:before {
    content: '\e954';
}
.CTICON_WASHING_MACHINE:before {
    content: '\e955';
}
.CTICON_WATER_BOTTLE:before {
    content: '\e956';
}
.CTICON_WIFI:before {
    content: '\e957';
}
.CTICON_WORKDESK:before {
    content: '\e958';
}
.CTICON_ALCOHOL:before {
    content: '\e959';
}
.CTICON_CALENDAR:before {
    content: '\e95a';
}
.CTICON_CHECKIN_CHECKOUT:before {
    content: '\e95b';
}
.CTICON_COUPLE_FRIENDLY:before {
    content: '\e95c';
}
.CTICON_DC_CC:before {
    content: '\e95d';
}
.CTICON_EIGHTEEN_BELOW:before {
    content: '\e95e';
}
.CTICON_FREE_CANCELLATION:before {
    content: '\e95f';
}
.CTICON_FREE_MEAL:before {
    content: '\e960';
}
.CTICON_GOVT_GUIDELINES:before {
    content: '\e961';
}
.CTICON_LOCATION:before {
    content: '\e962';
}
.CTICON_NO_AIR_CONDITIONER:before {
    content: '\e963';
    color: #808080;
}
.CTICON_NO_BATHROOM:before {
    content: '\e964';
    color: #808080;
}
.CTICON_NO_BUSINESS_CENTRE:before {
    content: '\e965';
    color: #808080;
}
.CTICON_NO_CHIDLREN_SERVICES:before {
    content: '\e966';
    color: #808080;
}
.CTICON_NO_FITNESS:before {
    content: '\e967';
    color: #808080;
}
.CTICON_NO_FRONT_DESK:before {
    content: '\e968';
    color: #808080;
}
.CTICON_NO_HOUSEKEEPING:before {
    content: '\e969';
    color: #808080;
}
.CTICON_NO_INDOOR_PLAY:before {
    content: '\e96a';
    color: #808080;
}
.CTICON_NO_LIFT:before {
    content: '\e96b';
    color: #808080;
}
.CTICON_NO_LOUNGE:before {
    content: '\e96c';
    color: #808080;
}
.CTICON_NO_MEAL_SERVICES:before {
    content: '\e96d';
    color: #808080;
}
.CTICON_NO_PARKING:before {
    content: '\e96e';
    color: #808080;
}
.CTICON_NO_POWER_BACKUP:before {
    content: '\e96f';
    color: #808080;
}
.CTICON_NO_RESTAURANT:before {
    content: '\e970';
    color: #808080;
}
.CTICON_NO_ROOM_SERVICE:before {
    content: '\e971';
    color: #808080;
}
.CTICON_NO_SECURITY:before {
    content: '\e972';
    color: #808080;
}
.CTICON_NO_SHUTTLE_SERVICE:before {
    content: '\e973';
    color: #808080;
}
.CTICON_NO_SWIMMING_POOL:before {
    content: '\e974';
    color: #808080;
}
.CTICON_NO_WIFI:before {
    content: '\e975';
    color: #808080;
}
.CTICON_NOT_ALLOWED_ALCOHOL:before {
    content: '\e976';
}
.CTICON_NOT_ALLOWED_DC_CC:before {
    content: '\e977';
}
.CTICON_NOT_ALLOWED_EIGHTEEN_BELOW:before {
    content: '\e978';
}
.CTICON_NOT_ALLOWED_OUTSIDE_FOOD:before {
    content: '\e979';
}
.CTICON_NOT_ALLOWED_PETS:before {
    content: '\e97a';
}
.CTICON_NOT_ALLOWED_UNMARRIED_COUPLE:before {
    content: '\e97b';
}
.CTICON_OUTSIDE_FOOD:before {
    content: '\e97c';
}
.CTICON_SHOPS:before {
    content: '\e97d';
}
.CTICON_UNMARRIED_COUPLE:before {
    content: '\e97e';
}
.CTICON_VERIFIED:before {
    content: '\e97f';
}
.CTICON_WELCOME_GIFT:before {
    content: '\e980';
}
.CTICON_ACCESSIBLE_FACILITIES:before {
    content: '\e981';
}
.CTICON_ADVENTURE:before {
    content: '\e982';
}
.CTICON_AEROPLANE:before {
    content: '\e983';
}
.CTICON_ALARM_CLOCK:before {
    content: '\e984';
}
.CTICON_BANKING:before {
    content: '\e985';
}
.CTICON_BANQUET:before {
    content: '\e986';
}
.CTICON_BASKETBALL:before {
    content: '\e987';
}
.CTICON_BEAUTY_SERVICE:before {
    content: '\e988';
}
.CTICON_BOOK:before {
    content: '\e989';
}
.CTICON_BUSINESS_CENTRE:before {
    content: '\e98a';
}
.CTICON_CAFETERIA:before {
    content: '\e98b';
}
.CTICON_CASH:before {
    content: '\e98c';
}
.CTICON_CATERING:before {
    content: '\e98d';
}
.CTICON_CCTV:before {
    content: '\e98e';
}
.CTICON_CURRENCY_EXCHANGE:before {
    content: '\e98f';
}
.CTICON_FNB_KIOSK:before {
    content: '\e990';
}
.CTICON_FRONT_DESK:before {
    content: '\e991';
}
.CTICON_ICE_MACHINE:before {
    content: '\e992';
}
.CTICON_KEYCARD:before {
    content: '\e993';
}
.CTICON_LAWN:before {
    content: '\e994';
}
.CTICON_LIQUOR:before {
    content: '\e995';
}
.CTICON_LIVE_TV:before {
    content: '\e996';
}
.CTICON_LUGGAGE:before {
    content: '\e997';
}
.CTICON_MASSAGE:before {
    content: '\e998';
}
.CTICON_MEAL_SERVICES:before {
    content: '\e999';
}
.CTICON_MULTILINGUAL:before {
    content: '\e99a';
}
.CTICON_NEWSPAPER:before {
    content: '\e99b';
}
.CTICON_NIGHTLIFE:before {
    content: '\e99c';
}
.CTICON_OUTDOOR_RECREATION:before {
    content: '\e99d';
}
.CTICON_PACKAGE:before {
    content: '\e99e';
}
.CTICON_PARKING:before {
    content: '\e99f';
}
.CTICON_PETS:before {
    content: '\e9a0';
}
.CTICON_PRINT:before {
    content: '\e9a1';
}
.CTICON_RESTAURANT:before {
    content: '\e9a2';
}
.CTICON_SAUNA:before {
    content: '\e9a3';
}
.CTICON_SECURITY:before {
    content: '\e9a4';
}
.CTICON_SHUTTLE_SERVICE:before {
    content: '\e9a5';
}
.CTICON_SPECIALIZED_STAFF:before {
    content: '\e9a6';
}
.CTICON_TERRACE:before {
    content: '\e9a7';
}
.CTICON_TORCH:before {
    content: '\e9a8';
}
.CTICON_VENDING_MACHINE:before {
    content: '\e9a9';
}
.CTICON_WATER_ACTIVITIES:before {
    content: '\e9aa';
}
.CTICON_WATER:before {
    content: '\e9ab';
}
.CTICON_WEDDING:before {
    content: '\e9ac';
}
