.circular-progress {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-circle {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
}

.percentage {
    position: relative;
}
