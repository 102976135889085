.results-slot-container {
    max-width: 1224px;
    margin: 0 auto;
}

.suggestion-icon {
    svg {
        width: 24px;
        height: 24px;
    }
}

.stepper-container {
    max-width: 53px;
}
.stepper-circle {
    width: 5px;
    position: relative;
    height: 5px;
    border-radius: 50%;
    border: 1px solid #f77728;
}
.stepper-bar {
    top: 2px;
    width: 53px;
    height: 1px;
}