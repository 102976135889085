.itinerary_summary__card {
    &__divider {
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 10px;
            border-top: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;

            top: -6px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background: #fff;
            box-shadow: inset 0px 4px 16px 0px rgba(26, 26, 26, 0.08);

            transform: rotate(-45deg);
        }

        &::before {
            left: -23px;
            transform: rotate(90deg);
        }

        &::after {
            right: -23px;
            transform: rotate(-90deg);
        }
    }
}

.itinerary_summary_box-cover-left {
    position: absolute;
    top: 16px;
    background: #f4f4f4;
    width: 2px;
    height: 18px;
    left: -2px;
}

.itinerary_summary_box-cover-right {
    position: absolute;
    top: 16px;
    background: #f4f4f4;
    width: 2px;
    height: 18px;
    right: -2px;
}

.room-nights {
    background: #f3f3f3;
    width: 64px;
    height: 24px;
}
