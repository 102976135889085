.slick-slider {
    position: relative;
}

.image-slider {
    position: relative;

    .image-count-chip {
        width: 54px;
        height: 36px;
        bottom: 16px;
        border-radius: 24px;

        right: 16px;

        background: rgba(26, 26, 26, 0.4);
    }

    .image-slider-dots-container {
        position: absolute;
        bottom: 15px;
        display: flex;
        justify-content: center;

        .image-slider-dots-wrapper {
            max-width: 52px;
            overflow: hidden;
        }

        .image-slider-dots-slider {
            display: flex;
            gap: 5px;

            transition: transform 500ms;
        }

        .image-slider-dot {
            position: relative;
            height: 6px;
            width: 6px;
            opacity: 0.5;
            background: #ffff;
            border-radius: 50%;
            flex-shrink: 0;

            transition: transform 500ms, background 500ms;
        }

        // Added below becuase above one was not overriding the style
        .active {
            background: #ffff;
            opacity: 1;
        }
    }

    &__shadow {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.72%, rgba(0, 0, 0, 0.5) 100%);
    }

    &__leftChevron-active {
        top: 108px;
        left: 16px;
        opacity: 1;
        transition: opacity 500ms ease-out;
    }

    &__rightChevron-active {
        top: 108px;
        right: 16px;
        opacity: 1;
        transition: opacity 500ms ease-out;
    }

    &__leftChevron-hidden {
        top: 108px;
        left: 16px;
        opacity: 0;
        transition: opacity 500ms ease-out;
    }

    &__rightChevron-hidden {
        top: 108px;
        right: 16px;
        opacity: 0;
        transition: opacity 500ms ease-out;
    }
}
