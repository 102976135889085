.circular {
    &__progress {
        border-radius: 50%;
        box-shadow: 0px 0px 0px 3px #f3f3f3 inset;
    }

    &__text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
}
