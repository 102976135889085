.bg-white {
    background-color: #ffffff;
}

.bg-error {
    background-color: #db1f1f !important;
}

.c-neutral-400 {
    color: #999;
}

.intl-success {
    color: #11A670
}

.bc-neutral-100 {
    color: "#e6e6e6"
}