.summary__card {
    box-shadow: 0px 4px 16px 0px rgba(26, 26, 26, 0.08);
    border: 1px solid #e6e6e6;

    &__divider {
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 10px;
            border-top: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;

            top: -6px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background: #fff;
            box-shadow: inset 0px 4px 16px 0px rgba(26, 26, 26, 0.08);

            transform: rotate(-45deg);
        }

        &::before {
            left: -30px;
            transform: rotate(90deg);
        }

        &::after {
            right: -30px;
            transform: rotate(-90deg);
        }
    }
}

.room-nights {
    background: #f3f3f3;
    width: 64px;
    height: 24px;
}

.itinerary_dotted_divider {
    left: -9px;
    z-index: -1;
}

.box-cover-left {
    position: absolute;
    top: -10px;
    background: #f4f4f4;
    width: 2px;
    height: 19px;
    left: -1px;
}

.box-cover-right {
    position: absolute;
    top: -10px;
    background: #f4f4f4;
    width: 2px;
    height: 19px;
    right: -1px;
}

.bottom__header__border {
    border-bottom: 1px solid #e7e7e7;
}

.header__divider {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: calc((100vw - 100%) / -2);
        width: calc((100vw - 98%) / 2);
        height: 100%;
        bottom: -1px;
        border-bottom: 1px solid #e7e7e7;
        background: #ffffff;
    }

    &::after {
        content: '';
        position: absolute;
        width: calc((100vw - 98%) / 2);
        right: calc((100vw - 100%) / -2);
        height: 1px;
        bottom: -1px;
        border-bottom: 1px solid #e7e7e7;
        background: #ffffff;
    }
}

.itinerary__otherguests__in {
    transition: height 300ms;
    height: 64px;
}

.itinerary__otherguests__out {
    height: 0px;
    transition: height 300ms;
    overflow: hidden;
}
