.horizontal-slider {
    width: 100%;
    max-width: 700px;
    height: 20px;
}

.slider-track {
    position: relative;

    background: #f3f3f3;
    border-radius: 4px;
}

.slider-track.slider-track-1 {
    background: #1a1a1a;
}

.slider-track.slider-track-2 {
    background: #f3f3f3;
}

.horizontal-slider .slider-track {
    top: 20px;
    height: 4px;
}

.horizontal-slider .slider-thumb {
    top: 11px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 1px solid #1a1a1a;
    border-radius: 50%;
    cursor: pointer;
}
