.m-R-1 {
    margin-right: 1px;
}

.m-B-2 {
    margin-bottom: 2px;
}
//Added in design system too, Remove from here after deploying this in design system.
.ml-12 {
    margin-left: 48px;
}

.n-ml-1 {
    margin-left: -4px;
}

.n-mr-2 {
    margin-right: -8px;
}

.n-ml-8 {
    margin-left: -32px;
}

.ml-86 {
    margin-left: 86px;
}

.n-mt-2 {
    margin-top: -8px;
}

.w-max-content {
    width: max-content;
    width: -webkit-max-content;
}
.w-auto {
    width: auto;
}
