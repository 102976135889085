.flightSuggestList {
    li:hover {
        background-color: #f3f3f3 !important;
    }
}

.flightSuggestionList:hover {
    background-color: #f3f3f3;
}

//Added wrapper of homepage traveller
.homePageTraveller {
    .dropDownBg:hover {
        background-color: #36c;
    }
}
.dropDown_menu_widthImp {
    width: 360px !important;
}

.filter--footer--shadow {
    box-shadow: 0px 4px 16px 0px #1a1a1a14;
}

.filter--header--shadow {
    box-shadow: 0px 4px 16px 0px rgba(26, 26, 26, 0.08);
}

.flex-cg-3 {
    column-gap: 12px !important;
}

.swap-right {
    animation: swapRight 0.7s 1 ease-in-out;
}
@keyframes swapRight {
    0% {
        transform: translateX(0%);
        border-right-color: transparent;
    }
    60% {
        transform: translateX(-60%);
        opacity: 0;
        border-right-color: transparent;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
        border-right-color: transparent;
    }
}

.swap-left {
    animation: swapLeft 0.7s 1 ease-in-out;
}
@keyframes swapLeft {
    0% {
        transform: translateX(0%);
        border-left-color: transparent;
    }
    60% {
        transform: translateX(60%);
        opacity: 0;
        border-left-color: transparent;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
        border-left-color: transparent;
    }
}

.rotateSwap180 {
    animation: rotate180 0.5s 1 ease-in-out;
}
@keyframes rotate180 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}
