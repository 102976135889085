html,
body,
#__next {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.base-container {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.ta-center {
    text-align: center;
}

.vertically-centre {
    transform: translateY(-50%);
}

// TODO @raghav add to @cleartrip/ct-design
.text-align-end {
    text-align: end;
}

.modify-search-textfield div {
    background-color: #fff;

    input {
        &:disabled {
            border-top: solid 1px #e6e6e6;
            border-bottom: solid 1px #e6e6e6;
            border-left: transparent;
            border-right: transparent;
            color: #000 !important;
            -webkit-text-fill-color: #000;
        }
    }
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.h-overflow-x {
    overflow-x: hidden;
}

.modify-search-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: max-content;
}
.disable-modify-search-textfield div {
    input {
        &:disabled {
            border-top: solid 1px #e6e6e6;
            border-bottom: solid 1px #e6e6e6;
            border-left: transparent;
            border-right: transparent;
        }
    }
}

.d-inline {
    display: inline;
}

.d-line-block {
    display: inline-block;
}
// TODO: Shift to ct-design
.w-max-content {
    width: max-content;
}

.bc-neutral-400 {
    border-color: #e7e7e7;
}

.reset-input-height input {
    height: auto;
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.primary-border {
    border: 1px solid #1a1a1a;
}

.disable-pointer-events {
    pointer-events: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.h-min-100p {
    min-height: 100%;
}

.c-inherit-imp {
    color: inherit !important;
}

.rating-highlight-child-container {
    margin-left: 40px;

    .icon:nth-child(1) {
        margin-left: -40px;
        position: relative;
        top: 5px;
    }
}
.bt-dotted {
    border-top: dotted 1px #e6e6e6;
}

.c-neutral-700.c-neutral-700 {
    color: #808080;
}

.override-svg svg.c-pointer {
    path {
        stroke: 808080;
    }
}

.override-svg-focused {
    .field-root.field-root {
        outline-color: #1a1a1a;
        box-shadow: 0px 4px 16px 0px #1a1a1a14;
        &:hover {
            box-shadow: 0px 4px 16px 0px #1a1a1a14;
            outline-width: 1px;
            outline-color: #1a1a1a;
        }
    }
    svg.c-pointer {
        path {
            stroke: #1a1a1a;
        }
    }
}

.tab-typography-c-pointer {
    p {
        cursor: pointer;
    }
}

.tab-typography-c-pointer {
    p {
        cursor: pointer;
    }
}

.t-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

.stopScrolling {
    height: 100%;
    overflow: hidden;
}
.socialMsgAnimation {
    animation: slideInTextAnimation 4s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    transform: translateY(0px);
}
@keyframes slideInTextAnimation {
    0% {
        transform: translateY(0px);
    }
    10% {
        transform: translateY(-36px);
    }
    25% {
        transform: translateY(-32px);
    }
    50% {
        transform: translateY(-32px);
    }
    60% {
        transform: translateY(-68px);
    }
    75% {
        transform: translateY(-64px);
    }
    100% {
        transform: translateY(-64px);
    }
}

.bb-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.nmx-4 {
    margin-left: -16px;
}