.fromDiv {
    border: 1px solid #e6e6e6;
    border-right: 0 !important;
    border-radius: 6px 0 0 6px;
    height: 52px;
    width: 100%;
    padding: 14px 16px;
}
.toDiv {
    border: 1px solid #e6e6e6;
    border-left: 0 !important;
    border-radius: 0 6px 6px 0;
    height: 52px;
    padding: 14px 16px;
    width: 100% !important;
}

.divider-wrapper {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    height: 52px;
}

.divider {
    height: 36px;
    width: 1px;
    background-color: #e6e6e6;
}
