.fromDiv {
    border: 1px solid #e6e6e6;
    border-right: 0;
    height: 52px;
    padding: 0 16px;
}

.toDiv {
    border: 1px solid #e6e6e6;
    border-left: 0;
    height: 52px;
    padding: 0 16px;
    width: 135px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-24 {
    margin-left: 24px;
}

.p-8tbr-12 {
    padding: 8px 8px 8px 12px;
}

.ml-12px {
    margin-left: 12px;
}

.mr-8px {
    margin-right: 8px;
}

.p-16-19-16-16 {
    padding: 16px 19px 16px 16px;
}

.divider-wrapper {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    height: 52px;
}

.divider {
    height: 36px;
    width: 1px;
    background-color: #e6e6e6;
}

.c-neutral-400 {
    color: #999999;
}

.nmr-3 {
    margin-right: -12px;
}

.t-48 {
    top: 48px;
}
.t-52 {
    top: 52px;
}
.t-all {
    transition: all 0.2s ease;
}
