.c-blue {
    color: #36c;
}

.hoverGrey:hover {
    background-color: #f7f7f7;

    svg {
        circle {
            stroke: #36c;
        }

        path {
            fill: #36c;
        }
    }
}

.nav:hover {
    background-color: #eff5fc;

    div {
        color: #36c;
    }

    &.svg:not(.hotelsnav) {
        svg {
            fill: #36c;
        }
    }
}
.hotelsnav {
    &.svg {
        svg {
            stroke: #1a1a1a;
        }
    }
}
.hotelsnav:hover {
    background-color: #eff5fc;

    div {
        color: #36c;
    }

    &.svg {
        svg {
            stroke: #36c;
        }
    }
}
:focus {
    outline: none;
}
::-moz-focus-inner {
    border: 0;
}
::placeholder {
    color: #999999;
}

input,
Select {
    &:disabled {
        border: solid 1px #f7f7f7;
        color: #ccc;
        opacity: 1;
        cursor: not-allowed;
        &::placeholder {
            color: #ccc;
        }

        & + .select__icon {
            color: #ccc;
        }
        & + .field__icon {
            fill: #ccc;
        }
    }
}

.select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    padding: 4px 24px 4px 12px !important;
    position: relative;
    outline: 0;

    &__icon {
        position: absolute;
        right: 8px;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        color: #999;
        pointer-events: none;
    }

    option[value=''],
    option[disabled],
    option[hidden] {
        color: #999;
    }
}

.bc-grey-20 {
    border-color: #ccc;
}

.bc-blue {
    border-color: #36c;
}

.td-underline {
    &:hover {
        text-decoration: underline;
    }
}
.c-secondary-500 {
    color: #36c;
}

.c-neutral-400 {
    color: #999999;
}

.lh-copy {
    line-height: 1.5;
}

.t-uppercase {
    text-transform: uppercase;
}
.bs-shadow-hbs {
    box-shadow: 0 2px 24px rgb(0 0 0 / 4%);
}

.overlay-bg {
    background-color: rgba(0, 0, 0, 0.2) !important;
    animation: fadein 350ms 1 cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

.dropdown {
    &__item:hover {
        background-color: #f3f3f3;
        border-radius: 6px;
    }

    &__option:hover {
        background-color: #f7f7f7;
    }

    &__underline:hover {
        text-decoration: underline;
    }
}

.loginBanner {
    .awssld {
        height: 362px !important;
    }

    .awssld__wrapper {
        height: 445px !important;
    }

    .banner-slider-image {
        margin-top: -54px;
        width: 350px;
    }

    .awssld__bullets .awssld__bullets--active {
        width: 6px !important;
        height: 6px !important;
        background: #ff4f17 !important;
        border-radius: 30px !important;
        transform: none !important;
    }
    
    .awssld__timer {
        background: transparent;
    }
}
.carousalImg {
    .awssld__timer {
        opacity: 0;
        pointer-events: none;
    }
}

@keyframes heightAnimate {
    0% {
        top: 90px;
        opacity: 0;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

.bannerAnimation {
    animation: heightAnimate 0.4s linear;
}

.primary-text-container-home {
    &:hover {
        border-radius: 8px;
        background-color: #d6e8fc;
        .hightlight-text {
            color: #36c;
        }
    }
}

.mr-48 {
    margin-right: 48px;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .mr-0--xs {
        margin-right: 0px;
    }
}
@media only screen and (max-width: 1155px) {
    .mr-48 {
        margin-right: 0px;
    }
}

.mobile-input {
    &__correct {
        input {
            line-height: 20px;
            font-size: 14px;
            border-radius: 4px;
            width: 100%;
            outline: none;
            border: solid 1px #e6e6e6;
            color: #1a1a1a;

            &:focus {
                outline: none;
                border: solid 1px #36c;
            }
        }
    }
    &__error {
        input {
            line-height: 20px;
            font-size: 14px;
            border-radius: 4px;
            width: 100%;
            outline: none;
            border: solid 1px #ff3c2b;
            color: #1a1a1a;
        }
        div {
            color: #ff3c2b;
        }
    }
}

.checkbox {
    div {
        margin: 0px;
    }
}

.link--hover:hover {
    background-color: #eff5fc;
    border-radius: 6px;
    color: #36c;
}

.dot {
    height: 8px;
    width: 8px;
    background-color: #cc1414;
    border-radius: 10px;
    display: inline-block;
    animation: blink 1.2s ease-in-out infinite none;
}
@keyframes blink {
    0% {
        box-shadow: 0 0 0 0 #f0b9b9;
    }
    100% {
        box-shadow: 0 0 0 6px #fae8e8;
    }
}

// TODO@elroi move this over to ct-design
.flex-cg-2 {
    column-gap: 8px;
}

.dropdown-container {
    border-radius: 6px;
}

.field-1:focus-within,
.field-2:focus-within {
    border: solid 1px;
    border-color: #1a1a1a;
    border-radius: 6px;
    z-index: 99999;
    background-color: #fff;
}

.field-2:focus-within {
    .icon {
        padding-left: 16px;
    }
}

.dropdown {
    z-index: 999;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    border-radius: 12px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.airportList {
    li:hover {
        background: #f3f3f3;
        cursor: pointer;
        .airport-code {
            background-color: #000;
            color: #fff;
        }
    }
}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b3b3b3;
    font-weight: 500;
    font-size: 16px;
}
.airline__search::placeholder {
    color: #b3b3b3;
    font-weight: 500;
    font-size: 14px;
}
.error {
    border-color: #cc1414 !important;
}

.error-from {
    border: 1px solid #cc1414 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.error-to {
    border: 1px solid #cc1414 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.ln-1 {
    left: -2px;
}

.rotate180 {
    animation: rotate180 0.5s 1 ease-in-out;
}
@keyframes rotate180 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.rotate180-enter {
    transform: rotate(180deg);
}

.rotate180-enter.rotate180-enter-active {
    transform: rotate(180deg);
}

.rotate180-leave {
    transform: rotate(180deg);
}

.rotate180-leave.rotate180-leave-active {
    transform: rotate(180deg);
}
