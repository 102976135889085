.search-box {
    input {
        height: 24px;
        width: 238px;
        outline: none;
        border: none;
        color: #1a1a1a;

        &:focus {
            outline: none;
            border: none;
        }
    }

    .search-cross-icon {
        right: 12px;
    }

    border: 1.5px solid rgba(26, 26, 26, 1);
    border-radius: 8px;
}
