.cfwSlideUpText {
    animation: slideUpText 10s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    transform: translateY(0px);
}

@keyframes slideUpText {
    0% {
        transform: translateY(0px);
    }
    7% {
        transform: translateY(-18px);
    }
    14% {
        transform: translateY(-16px);
    }
    21% {
        transform: translateY(-16px);
    }
    28% {
        transform: translateY(-34px);
    }
    35% {
        transform: translateY(-32px);
    }
    42% {
        transform: translateY(-32px);
    }
    49% {
        transform: translateY(-50px);
    }
    55% {
        transform: translateY(-48px);
    }
    61% {
        transform: translateY(-48px);
    }
    67% {
        transform: translateY(-66px);
    }
    73% {
        transform: translateY(-64px);
    }
    79% {
        transform: translateY(-64px);
    }
    85% {
        transform: translateY(-82px);
    }
    91% {
        transform: translateY(-80px);
    }
    100% {
        transform: translateY(-80px);
    }
}
