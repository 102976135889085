@import './components/';
@import './stylekit/';
@import './oldStyles/';
@import './home/index.scss';
@import './customIcon/index.scss';
@import './itinerary/index.scss';

@import './details/index.scss';
@import './itinerary/index.scss';
@import './pages/';
@import './results/index.scss';
@import './seo/index.scss';
@import './common.scss';
@import 'node_modules/@cleartrip/ct-design-styles/dist/styles/index.css';
