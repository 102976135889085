.inclusionCardWrapper {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
}

.roomSelectionFilterWrapper {
    border: 1px solid #e7e7e7;
    border-radius: 8px;

    transition: box-shadow 500ms, border 500ms, padding 500ms;
}

.flex-baseline {
    align-items: baseline;
}

.dropdown-main-item:hover {
    background: #f3f3f3;
}

.t-50 {
    top: 50%;
}

.imageGallery {
    transition: filter 500ms;
    &:hover {
        filter: brightness(0.5);
    }
}

.details-header > div {
    position: absolute;
    top: 0;
    left: 0;
}
.componentVisible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.componentInvisible {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
}

.card-price {
    min-width: max-content;
}

.c-h-100vh > div {
    height: 100vh;
}

/* TODO @raghav add this class to @cleartrip/ct-design */
.absoluteCentre {
    display: flex;
    justify-content: center;
    align-items: center;
}

.maxContent {
    min-width: max-content;
}

.userAccountDropdownOption {
    &:hover {
        background: #f3f3f3;
    }
}

.tooltip-spacing > div:first-child {
    margin-top: 24px;
    margin-left: -140px;
}

.dotted-line {
    border: 1px dashed #e7e7e7;
}

.selectedComment {
    box-shadow: 24px 0 0 0 #f3f3f3, -24px 0 0 0 #f3f3f3;
    animation: HighlightComment;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes HighlightComment {
    0%,
    40%,
    80%,
    100% {
        box-shadow: 24px 0 0 0 #f3f3f3, -24px 0 0 0 #f3f3f3;
        background: #f3f3f3;
    }
    20%,
    60%,
    90% {
        box-shadow: 24px 0 0 0 #f3f3f3, -24px 0 0 0 #ffffff;
        background: #ffffff;
    }
}

.roomSticky {
    .roomSelectionFilterWrapper {
        box-shadow: 0px 15px 10px -15px rgba(26, 26, 26, 0.08);
        border: none;
        border-radius: 0;

        padding: 0;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            left: calc((100vw - 100%) / -2);
            box-shadow: 0px 15px 10px -15px rgba(26, 26, 26, 0.08);
            width: calc((100vw - 98%) / 2);
            background: white;
        }

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            right: calc((100vw - 100%) / -2);
            box-shadow: 0px 15px 10px -15px rgba(26, 26, 26, 0.08);
            width: calc((100vw - 98%) / 2);
            background: white;
        }
    }
}

.fixedDivider {
    position: fixed;
    top: 127px;
    left: 0;
    height: 1px;
    width: 100vw;
}

.gallery-container {
    padding: 0 48px;
}

.page-v-padding {
    padding: 0 72px;
}

.fit-content {
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.room--inclusions--header {
    height: 56px;
}

.image-fit-cover {
    object-fit: cover;
}

.navtab-divider {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: calc((var(--wd) - 100%) / -2);
        width: calc((var(--wd) - 98%) / 2);
        border-bottom: 1px solid #e7e7e7;
    }

    &::after {
        content: '';
        position: absolute;
        width: calc((var(--wd) - 98%) / 2);
        right: calc((var(--wd) - 100%) / -2);
        border-bottom: 1px solid #e7e7e7;
    }
}

.component-stacked-slots {
    &:last-child {
        .room-available-divider {
            border: none;
        }
    }
}

.amenities--icon {
    padding-top: 1px;
}

.fade--in {
    animation-name: fadein;
    animation-duration: 350ms;
    z-index: 1000;
}

.fade--out {
    animation-name: fadeout;
    animation-duration: 350ms;
    z-index: 1000;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.details-about-property {
    max-height: 116px; // to fix details line clamp on safari
}

.prevent-visiblity-transition {
    transition: all 0ms;
}

// TODO@elroi -- move to ct-design
.text-strike {
    text-decoration: line-through;
}

.rnr-container {
    display: grid;
    column-gap: 16px;
    row-gap: 16px;

    /*
    do not change the order of &:has(> *:nth-child(1)) vs &:has(> *:nth-child(2)) vs &:has(> *:nth-child(3))
    they will start overriding each other ( lower rule has priority )
    */
    &:has(> *:nth-child(1)) {
        width: 100%;
    }

    &:has(> *:nth-child(2)) {
        grid-template-columns: 1fr 204px;
    }

    &:has(> *:nth-child(3)) {
        grid-template-columns: 288px 1fr;

        & div:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        & div:nth-child(2) {
        }

        & div:nth-child(3) {
        }
    }
}
