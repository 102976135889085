.semi-circle-top {
    position: absolute;
    background: white;
    height: 4px;
    width: 8px;
    border-radius: 0 0 6px 6px;
    top: 0;
    left: 36px;
}

.toastCopyWidth {
    width: 280px;
}

.semi-circle-bottom {
    position: absolute;
    background: white;
    height: 4px;
    width: 8px;
    border-radius: 6px 6px 0 0;
    bottom: 0;
    left: 36px;
}

.ticket {
    display: flex;
    padding: 8px;
    background: #ecf3ff;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: fit-content;
    position: relative;
}
